import React, { useEffect, useState } from 'react';
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage';
import { motion } from 'framer-motion';
import './Home.css';
import Logo from '../assets/Savannah-Horn.png';
import GithubImg from '../assets/github.png';
import LinkedIn from '../assets/linkedin.png';
import { Wave } from 'react-animated-text';
import AOS from "aos";
import "aos/dist/aos.css";

const txtStyle = {
    display: "inline-block",
    marginBottom: "1em",
    padding: "2em 1em 1em 1em",
    width: "100%",
    fontSize: "4.5rem",
    color: "#868B8E"
};

export const Wave1 = () => (
    <div style={txtStyle}>
        <Wave text="CONTACT ME" effect="fadeOut" effectChange={1.0} duration={5.0} />
    </div>
);


function Home() {
    const [firstRun, setFirstRun] = useState(true);

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    useEffect(() => {
        const headings = document.querySelectorAll('.heading');
        const handleScroll = () => {
            const scrollValue = window.scrollY;
            headings.forEach((heading, index) => {
                heading.style.top = `${scrollValue * 0.07 * index}px`;
                if (firstRun) {
                    heading.style.transform = `translateY(5px)`;
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        const timeout = setTimeout(() => {
            headings.forEach((heading, index) => {
                heading.style.transform = `translateY(${index * 10}px)`;
            });
            setFirstRun(false);
        }, 800);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [firstRun]);

    return (
        <Fullpage>
            <FullPageSections>
                {/* Section Accueil */}
                <FullpageSection
                    className="section"
                    id="section-accueil"
                    style={{ scrollSnapAlign: 'start' }}
                >
                    <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 4, ease: 'easeOut' }}  // Transition plus lente
                    >
                        <div className="center">
                            <h1 className="heading">S.H</h1>
                            <h1 className="heading">S.H</h1>
                            <h1 className="heading">S.H</h1>
                            <h1 className="heading">S.H</h1>
                            <h1 className="heading">S.H</h1>
                            <h1 className="heading">S.H</h1>
                            <h1 className="heading">S.H</h1>
                        </div>
                    </motion.div>
                    <p className="first-section">Fullstack Developer</p>
                </FullpageSection>

                {/* Section À propos */}
                <FullpageSection
                    className="section"
                    id="section-apropos"
                    style={{ scrollSnapAlign: 'start' }}
                >
                    <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 3, ease: 'easeOut' }}  // Transition plus lente
                    >
                        <div className="container" style={{ justifyContent: 'space-between', gap: '10px' }}>
                            <div className="row" style={{ maxWidth: '100%' }}>
                                <div className="col-md-6 d-flex flex-column align-items-center align-self-start" >
                                    <div className='img-rotate'>
                                        <img src={Logo} style={{ width: 300 }} alt="Logo"></img>
                                    </div>
                                    <div className="about-me">
                                        <p style={{ fontSize: 22, textAlign: 'center' }}>Based in Strasbourg since 1997</p>
                                        <p style={{ fontSize: 20, textAlign: 'center' }}>Based in Strasbourg since 1997</p>
                                        <p style={{ fontSize: 18, textAlign: 'center' }}>Based in Strasbourg since 1997</p>
                                        <p style={{ fontSize: 16, textAlign: 'center' }}>Based in Strasbourg since 1997</p>
                                        <p style={{ fontSize: 14, textAlign: 'center' }}>Based in Strasbourg since 1997</p>
                                    </div>
                                </div>
                                <div className="col-md-6 second-row" style={{ flexDirection: 'column', alignItems: 'center' }}>
                                    <h1 className='neon'>ABOUT ME</h1>
                                    <p style={{ maxWidth: '400px', marginTop: '40px' }}>
                                        After spending over ten years in the hospitality industry, I decided to take a leap and follow my passion
                                        for the digital world by diving into web and mobile development.<br /><br />
                                        Sometimes, a change of direction is just what you need to open new doors!
                                        This career shift has been both challenging and incredibly rewarding.
                                        Now, with a degree in web development (Bachelor degree) under my belt,
                                        I’m specializing in full-stack development, with strong skills in JavaScript, React, and Node.js.
                                        <br /><br />But that’s just the beginning! My goal is to dive deeper into mobile development and IoT technologies –
                                        fields I’m truly excited about and believe have a bright future.
                                        With a unique blend of customer service experience and technical know-how, I bring a user-centered,
                                        innovative approach to each project. I’m ready to tackle new challenges and create modern, connected
                                        solutions that make an impact.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </FullpageSection>

                {/* Section Projets */}
                <FullpageSection
                    className="section"
                    id="section-projets"
                    style={{ scrollSnapAlign: 'start' }}
                >
                    <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 3, ease: 'easeOut' }}  // Transition plus lente
                    >
                        <div id='section-projets' style={{ height: '988px', overflowY: 'auto', width: '100%' }}>
                            <h1>Super Mario Timeline</h1>
                            <p>Initial launch dates of games in the Super Mario series.</p>
                            <ul className="timeline">
                                <li><a style={{ textDecoration: "none", color:"#fff" }} href='#' target='_blank'><time dateTime="2023-03">March 2023</time>My Spotify</a></li>
                                <li><a style={{ textDecoration: "none", color:"#fff" }} href='#' target='_blank'><time dateTime="2023-06">June 2023</time>My Snapchat App</a></li>
                                <li><a style={{ textDecoration: "none", color:"#fff" }} href='https://github.com/savannah-tvn/Projects/tree/main/twitter-academie-main' target='_blank'><time dateTime="2023-07">July 2023</time>My Twitter App</a></li>
                                <li><a style={{ textDecoration: "none", color:"#fff" }} href='https://github.com/savannah-tvn/Projects/tree/main/ecommerce-main' target='_blank'><time dateTime="2023-08">August 2023</time>E-commerce website</a></li>
                                <li><a style={{ textDecoration: "none", color:"#fff" }} href='https://github.com/savannah-tvn/Projects/tree/main/system-dockerization' target='_blank'><time dateTime="2023-09">September 2023</time>System Dockerization</a></li>
                                <li><a style={{ textDecoration: "none", color:"#fff" }} href='https://github.com/savannah-tvn/Projects/tree/main/enigma' target='_blank'><time dateTime="2023-10">October 2023</time>Enigma Algorithm</a></li>
                                <li><a style={{ textDecoration: "none", color:"#fff" }} href='https://github.com/savannah-tvn/Projects/tree/main/irc-main' target='_blank'><time dateTime="2024-01">January 2024</time>Internet Relay Chat</a></li>
                                <li><a style={{ textDecoration: "none", color:"#fff" }} href='https://github.com/savannah-tvn/Projects/tree/main/mern-stack-app' target='_blank'><time dateTime="2024-07">July 2024</time>To do list MERN Stack</a></li>
                                <li><a style={{ textDecoration: "none", color:"#fff" }} href='#' target='_blank'><time dateTime="2023-10">August 2024</time>Weather & Location Mobile App</a></li>
                                <li><a style={{ textDecoration: "none", color:"#fff" }} href='#' target='_blank'><time dateTime="2024-10">October 2024</time>Arcade Gaming</a></li>
                            </ul>
                        </div>
                    </motion.div>
                </FullpageSection>

                {/* Section Contact */}
                <FullpageSection
                    className="section"
                    id="section-contact"
                    style={{ scrollSnapAlign: 'start' }}
                >
                    <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 3, ease: 'easeOut' }}  // Transition plus lente
                    >
                        <Wave1 />
                        <div className='justify-content-center'>
                            <h3 style={{ textAlign: "center" }}>SAVANNAH.TANOVAN@GMAIL.COM</h3>
                        </div>
                        <div data-aos="fade-up" style={{ marginTop: 50, display: "flex", gap: "60px", alignItems: "center", justifyContent: "center" }}>
                            <a href='https://github.com/savannah-tvn/' target='_blank' >
                                <img data-aos="fade-right" src={GithubImg} style={{ width: "100px", filter: "invert(1)" }}></img>
                            </a>
                            <a href='https://www.linkedin.com/in/savannah-horn-tanovan-bb5645254/' target='_blank'>
                                <img data-aos="fade-left" src={LinkedIn} style={{ width: "100px", filter: "invert(1)" }}></img>
                            </a>
                        </div>
                    </motion.div>
                </FullpageSection>
            </FullPageSections>
        </Fullpage>
    );
}

export default Home;
