// src/App.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Home from './components/Home';

function App() {
  return (
    <div className="App">
      < Home/>
    </div>
  );
}

export default App;
